* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline-offset: 0;
}
body {
  background: #f3f4f6;
}

.clipPath {
  clip-path: polygon(0 0, 100% 0, 100% 72%, 0 34%);
  background-image: url("../assets/images/cars.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: blur(3px);
}

.Select {
  z-index: 2000000; /* Adjust this value as needed */
}

.swal2-container {
  z-index: 100000 !important;
}

@media print {
  .apexcharts-canvas {
    display: block !important;
  }
  .apexcharts-tooltip,
  .apexcharts-legend {
    display: none !important;
  }
}
